import React from "react";

import AverageRatingIcon from "~/assets/icons/AverageRating.svg?sprite";
import FolderIcon from "~/assets/icons/Folder.svg?sprite";
import PaperIcon from "~/assets/icons/Paper.svg?sprite";
import {
  ApiStatisticCard,
  statisticsProvider
} from "~/components/core/StatisticCard/StatisticCard";
import StatisticCardSkeleton from "~/components/core/StatisticCard/StatisticCardSkeleton";
import { trackEvent } from "~/utils/segment";

import { averageStudentRating } from "../DashboardLiveTutoringStatistic/DashboardLiveTutoringStatistic";
import { LazyDashboardComponent } from "../dashboardQueryState";
import { StatisticsWrapper } from "../styled";

const DashboardWritingLabStatistic: React.FC = (): JSX.Element => {
  React.useEffect(() => trackEvent("wl_load_general_stats", undefined), []);

  return (
    <StatisticsWrapper>
      <LazyDashboardComponent
        intersecionDebugName="wl: Papers Reviewed"
        placeholder={<StatisticCardSkeleton />}
        render={(apiQuery, ref) => (
          <ApiStatisticCard
            ref={ref}
            provider={statisticsProvider(
              "/api/v1/new_stats/wl/papers_reviewed/",
              x => x.papersReviewed
            )}
            apiQuery={apiQuery}
            icon={FolderIcon}
            caption="Papers Reviewed"
            clarification="Total number of papers reviewed during the time frame selected."
          />
        )}
      />

      <LazyDashboardComponent
        intersecionDebugName="wl: Avg. paper length"
        placeholder={<StatisticCardSkeleton />}
        render={(apiQuery, ref) => (
          <ApiStatisticCard
            ref={ref}
            provider={statisticsProvider(
              "/api/v1/new_stats/wl/average_paper_length/",
              x => x.averagePaperLength
            )}
            apiQuery={apiQuery}
            icon={PaperIcon}
            caption="Avg. paper length"
            clarification="Average paper length based on character count during the time frame selected."
          />
        )}
      />

      <LazyDashboardComponent
        intersecionDebugName="wl: Avg. student rating"
        placeholder={<StatisticCardSkeleton />}
        render={(apiQuery, ref) => (
          <ApiStatisticCard
            ref={ref}
            provider={statisticsProvider(
              "/api/v1/new_stats/wl/average_rating/",
              x => x.averageRating
            )}
            apiQuery={apiQuery}
            icon={AverageRatingIcon}
            caption="Avg. tutor rating"
            formatter={averageStudentRating}
            clarification="Average rating given by students to tutors for paper reviews."
          />
        )}
      />
    </StatisticsWrapper>
  );
};

export default DashboardWritingLabStatistic;
