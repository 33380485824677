import * as React from "react";

import ActiveStudentsIcon from "~/assets/icons/ActiveStudents.svg?sprite";
import AverageRatingIcon from "~/assets/icons/AverageRating.svg?sprite";
import TimeIcon from "~/assets/icons/Time.svg?sprite";
import {
  ApiStatisticCard,
  statisticsProvider
} from "~/components/core/StatisticCard/StatisticCard";
import StatisticCardSkeleton from "~/components/core/StatisticCard/StatisticCardSkeleton";
import styled from "~/components/core/styled";
import { trackEvent } from "~/utils/segment";

import { LazyDashboardComponent } from "../dashboardQueryState";
import { StatisticsWrapper } from "../styled";

const STUB_VALUE = "-";

function averageStudentRating(value: unknown): JSX.Element {
  let avgRating = STUB_VALUE;
  if (typeof value === "number" && Number.isFinite(value)) {
    avgRating = value.toFixed(2);
  }

  return (
    <AverageStudentRating>
      {avgRating}
      <OutOf>out of</OutOf> 5
    </AverageStudentRating>
  );
}

const DashboardFilteredStatistics: React.FC = () => {
  React.useEffect(() => {
    trackEvent("load_general_stats", undefined);
  }, []);

  return (
    <StatisticsWrapper>
      <LazyDashboardComponent
        intersecionDebugName="stats: Active Students"
        placeholder={<StatisticCardSkeleton />}
        render={(apiQuery, ref) => (
          <ApiStatisticCard
            ref={ref}
            provider={statisticsProvider(
              "/api/v1/new_stats/combined/active_students/",
              x => x.activeStudents
            )}
            apiQuery={apiQuery}
            icon={ActiveStudentsIcon}
            caption="Active Students"
            clarification="Number of students who used Pear Deck Tutor during the time frame selected."
          />
        )}
      />
      <LazyDashboardComponent
        intersecionDebugName="stats: Total hours used"
        placeholder={<StatisticCardSkeleton />}
        render={(apiQuery, ref) => (
          <ApiStatisticCard
            ref={ref}
            provider={statisticsProvider(
              "/api/v1/new_stats/combined/hours_used/",
              x => x.hoursUsed
            )}
            apiQuery={apiQuery}
            icon={TimeIcon}
            caption="Total hours used"
            clarification="Total combined hours of usage for Live Tutoring lessons and Writing Lab reviews during the time frame selected."
          />
        )}
      />
      <LazyDashboardComponent
        intersecionDebugName="stats: Avg. tutor rating"
        placeholder={<StatisticCardSkeleton />}
        render={(apiQuery, ref) => (
          <ApiStatisticCard
            ref={ref}
            provider={statisticsProvider(
              "/api/v1/new_stats/combined/average_rating/",
              x => x.averageRating
            )}
            apiQuery={apiQuery}
            icon={AverageRatingIcon}
            caption="Avg. tutor rating"
            formatter={averageStudentRating}
            clarification="Average rating given by students to tutors for both Live Tutoring lessons and Writing Lab reviews during the time frame selected."
          />
        )}
      />
    </StatisticsWrapper>
  );
};

export default DashboardFilteredStatistics;

const AverageStudentRating = styled.div`
  align-items: center;
  display: flex;
`;

const OutOf = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin: 0 10px;
  text-transform: uppercase;
`;
